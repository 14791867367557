// extracted by mini-css-extract-plugin
export var btnFilter = "courses-module--btnFilter--e4854";
export var courseBlock = "courses-module--courseBlock--34241";
export var courseContainer = "courses-module--courseContainer--110df";
export var courseContent = "courses-module--courseContent--7de86";
export var courseInfo = "courses-module--courseInfo--3e707";
export var courses = "courses-module--courses--a1c43";
export var coursesHeader = "courses-module--coursesHeader--3a60f";
export var coursesPageLayout = "courses-module--coursesPageLayout--51934";
export var filter = "courses-module--filter--75f52";
export var filterBlock = "courses-module--filterBlock--e118c";
export var filterOpen = "courses-module--filterOpen--c6ebf";
export var toggleSidebarButton = "courses-module--toggleSidebarButton--d95a7";
export var upcomingCourses = "courses-module--upcomingCourses--d164a";