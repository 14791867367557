import React from 'react'
import { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faGraduationCap, faStairs, faMedal, faAnglesLeft, faFilter } from '@fortawesome/free-solid-svg-icons'
import Seo from '../../components/Seo'
import AsideUpcomingCourses from '../../components/AsideUpcomingCourses'
import * as styles from '../../styles/pages/courses.module.scss'

export default function Courses({ data, location }) {
  const courseData = data.allSanityCourse
  const learningMethods = data.allSanityLearningMethod
  const studyFields = data.allSanityStudyField
  const accreditations = data.allSanityAccreditation
  const programTypes = data.allSanityProgramTypes
  const quickLinks = data.sanitySiteSettings.quickLinks

  // const [appliedFilters, setAppliedFilters] = useState({
  //   learningMethod: [],
  //   // Add other filters as needed
  // });

  // State to manage selected filters
  const [selectedFilters, setSelectedFilters] = useState({
    learningMethods: [],
    studyFields: [],
    accreditations: [],
    programTypes: [],
  });

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Parse the query parameters from the URL
    const urlSearchParams = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
  
    // Ensure that queryParams.learningMethod is an array or a single string even if no search present in url
    const learningMethodsFromQuery = Array.isArray(queryParams.learningMethod)
      ? queryParams.learningMethod
      : queryParams.learningMethod
      ? [queryParams.learningMethod]
      : [];
  
    // Set the selected filters
    setSelectedFilters({
      learningMethods: learningMethodsFromQuery,
      studyFields: [],
      accreditations: [],
      programTypes: [],
    });
  }, [location.search]);

  // Filter courses based on selected filters
  const filteredCourses = courseData.nodes.filter((course) => {
    const matchLearningMethods = selectedFilters.learningMethods.length === 0 ||
    course.courseStartDates.some(date => selectedFilters.learningMethods.includes(date?.learningMethod.title));
    
    const matchStudyFields =
      selectedFilters.studyFields.length === 0 ||
      course.studyFields.some((field) => selectedFilters.studyFields.includes(field.title));
  
    const matchAccreditations =
      selectedFilters.accreditations.length === 0 ||
      course.accreditation.some((accr) => selectedFilters.accreditations.includes(accr.title));
  
    const matchProgramTypes =
      selectedFilters.programTypes.length === 0 ||
      course.programTypes.some((type) => selectedFilters.programTypes.includes(type.title));
  
    return matchLearningMethods && matchStudyFields && matchAccreditations && matchProgramTypes;
  });

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Open sidebar when screen size is bigger than 768px 
  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth > 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFilterChange = (filterType, optionValue) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: [...prevFilters[filterType], optionValue],
    }));
  };

  const handleRemoveFilter = (filterType, optionValue) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].filter((value) => value !== optionValue),
    }));
  };


  return (
    <main>
      <Seo title='Courses' pathname='/courses' />

      <section className={styles.coursesPageLayout}>

        <div className={`${styles.filter} ${isSidebarOpen ? styles.filterOpen : ''}`}>
          <button className={styles.toggleSidebarButton} onClick={handleToggleSidebar}><FontAwesomeIcon icon={faAnglesLeft}/></button>

          <h3>Learning Methods</h3>
          <div className={styles.filterBlock}>
            {learningMethods.nodes.map((method) => (
              <div key={method.id}>
                <label>
                  <input
                    type="checkbox"
                    value={method.title}
                    checked={selectedFilters.learningMethods.includes(method.title)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleFilterChange('learningMethods', e.target.value);
                      } else {
                        handleRemoveFilter('learningMethods', e.target.value);
                      }
                    }}
                  />
                  {method.title}
                </label>
              </div>
            ))}
          </div>

          <h3>Study Fields</h3>
          <div className={styles.filterBlock}>
            {studyFields.nodes.map((field) => (
              <div key={field.id}>
                <label>
                  <input
                    type="checkbox"
                    value={field.title}
                    checked={selectedFilters.studyFields.includes(field.title)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleFilterChange('studyFields', e.target.value);
                      } else {
                        handleRemoveFilter('studyFields', e.target.value);
                      }
                    }}
                  />
                  {field.title}
                </label>
              </div>
            ))}
          </div>

          <h3>Accreditations</h3>
          <div className={styles.filterBlock}>
            {accreditations.nodes.map((field) => (
              <div key={field.id}>
                <label>
                  <input
                    type="checkbox"
                    value={field.title}
                    checked={selectedFilters.accreditations.includes(field.title)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleFilterChange('accreditations', e.target.value);
                      } else {
                        handleRemoveFilter('accreditations', e.target.value);
                      }
                    }}
                  />
                  {field.title}
                </label>
              </div>
            ))}
          </div>

          <h3>Programme Types</h3>
          <div className={styles.filterBlock}>
            {programTypes.nodes.map((field) => (
              <div key={field.id}>
                <label>
                  <input
                    type="checkbox"
                    value={field.title}
                    checked={selectedFilters.programTypes.includes(field.title)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleFilterChange('programTypes', e.target.value);
                      } else {
                        handleRemoveFilter('programTypes', e.target.value);
                      }
                    }}
                  />
                  {field.title}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.courses}>
        
          <div className={styles.courseContainer}>
            {filteredCourses.length < 1 && (
              <div style={{ width: '70vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{ fontWeight: 'bold', fontSize: '2rem'}}>No courses found</span>
              </div>
            )} 

            {filteredCourses.map((course) => (
              <Link to={course.slug.current} key={course.id} style={{ textDecoration: 'none'}}>
                <div className={styles.courseBlock}>
                  <GatsbyImage image={getImage(course.featuredImage?.asset)} alt={course.title} style={{ width: '100%', height: '40%', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}/>
                  <div className={styles.courseContent}>
                    <h1>{course.title}</h1>

                    <div className={styles.courseInfo}>
                      <ul>
                        {course.courseStartDates && (
                          <li>
                            <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '.5rem'}}/>
                            {course.courseStartDates.map((date) => date.learningMethod.title).join(' | ')}
                          </li>
                        )}
                        {course.nqfLevel && 
                          <li>
                            <FontAwesomeIcon icon={faStairs} style={{ marginRight: '.5rem'}}/>
                            NQF Level: {course.nqfLevel}
                          </li>}
                        {course.credits && 
                          <li>
                            <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '.5rem'}}/>
                            Credits: {course.credits}
                          </li>}
                        {course.accreditation.length > 0 && (
                          <li>
                            <FontAwesomeIcon icon={faMedal} style={{ marginRight: '.5rem'}}/>
                            {course.accreditation.map((accr) => accr.title).join(' | ')}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>



          <button className={styles.btnFilter}  onClick={handleToggleSidebar}>
            <FontAwesomeIcon icon={faFilter} />
          </button>
        </div>

        <aside className={styles.upcomingCourses} style={{ marginTop: '1rem'}}>
          <div className="aside-contents">
            <section>
              <h4>Upcoming Courses</h4>
              <AsideUpcomingCourses />
            </section>
  
            {quickLinks && quickLinks.length > 0 && (
              <section className="quick-links">
                <h4>Quick Links</h4>
                <ul>
                  {quickLinks.map(({title, slug, url}) => (
                    slug?.current 
                    ? <li key={slug.current}><Link to={`/${slug.current}/`}>{title}</Link></li>
                    : <li key={url}><a href={url}>{title}</a></li>
                  ))}
                </ul>
              </section>
            )}
          </div>
        </aside>
      </section>
    </main>
  );
}

export const query = graphql`
    query coursesQuery {
        allSanityCourse {
            nodes {
              id
              featuredImage {
                asset {
                  gatsbyImageData
                }
              }
              title
              saqaId
              learningProgrammeId
              nqfLevel
              credits
              studyFields {
                title
              }
              courseInformation {
                title
              }
              accreditation {
                title
              }
              programTypes {
                title
              }
              slug {
                current
              }
              courseStartDates {
                learningMethod {
                  title
                }
              }
            }
          }
          allSanityLearningMethod {
            nodes {
              id
              title
            }
          }
          allSanityStudyField {
            nodes {
            id
            title
          }
        }
        allSanityAccreditation {
          nodes {
            id
            title
          }
        }
        allSanityProgramTypes {
          nodes {
            id
            title
          }
        }
        sanitySiteSettings {
          quickLinks {
            title
            slug {
              current
            }
            url
          }
        }
    }
`